<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{$t('message.user_profile_text')}}</h1>
            <p class="lead">
              {{$t('message.action_proceed_registration')}}
            </p>
          </div>
          <form @submit.prevent="submit">
            <b-row>
              <b-col sm="12">
                <!-- Nazione -->
                <validation-field
                  :label="$t('message.label_country')"
                  :v="$v.form.country"
                >
                  <select
                    class="form-control"
                    v-model="form.country"
                    v-on:blur="$v.form.country.$touch()"
                    v-on:change="changeVatScheme()">
                    <option></option>
                    <option
                      :value="countryRow.Code"
                      v-for="(countryRow , i) in countries"
                    >{{countryRow.Translation}}
                    </option>
                  </select>
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- Tipologia di cliente -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.registration_type')"
                  :v="$v.form.type"
                >
                  <select class="form-control" v-model="form.type"
                          v-on:blur="$v.form.type.$touch()">
                    <option></option>
                    <option value="0">{{$t('message.registration_option_0')}}
                    </option>
                    <option value="1">{{$t('message.registration_option_1')}}
                    </option>
                    <option v-if="isIta" value="2">
                      {{$t('message.registration_option_2')}}
                    </option>
                    <option v-if="isIta" value="3">
                      {{$t('message.registration_option_3')}}
                    </option>
                  </select>
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="showBusinessName">
              <!-- prima parte nominativo -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.business_fullname')"
                  :v="$v.form.businessName"
                >
                  <input
                    :placeholder="$t('message.placeholder_business_fullname')" class="form-control"
                    maxlength="50"
                    type="text" v-model="form.businessName"
                    v-on:blur="$v.form.businessName.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="showBusinessName">
              <!-- seconda parte nominativo -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.business_fullname2')"
                  :v="$v.form.businessName2"

                >
                  <input
                    :placeholder="$t('message.placeholder_business_fullname2')" class="form-control"
                    maxlength="50"
                    type="text" v-model="form.businessName2"
                    v-on:blur="$v.form.businessName2"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <!-- nome -->
            <b-row v-if="showFirstName">
              <b-col sm="12">
                <validation-field
                  :label="$t('message.first_name')"
                  :v="$v.form.name"
                >
                  <input
                    class="form-control"
                    type="text"
                    maxlength="30"
                    v-model="form.name"
                    v-on:blur="$v.form.name.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <!-- cognome -->
            <b-row v-if="showLastName">
              <b-col sm="12">
                <validation-field
                  :label="$t('message.last_name')"
                  :v="$v.form.surname"
                >
                  <input
                    class="form-control" id="surname" type="text"
                    v-model="form.surname"
                    maxlength="30"
                    v-on:blur="$v.form.surname.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- indirizzo -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.address')"
                  :v="$v.form.address"
                >
                  <input
                    class="form-control"
                    id="address"
                    maxlength="50"
                    type="text"
                    v-model="form.address"
                    v-on:blur="$v.form.address.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- indirizzo seconda parte -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.address2')"
                  :v="$v.form.address2">
                  <input
                    class="form-control"
                    maxlength="50"
                    type="text"
                    v-model="form.address2"
                    v-on:blur="$v.form.address2.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- cap  -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.post_code')"
                  :v="$v.form.postalCode"
                >
                  <input
                    class="form-control"
                    maxlength="20"
                    type="text"
                    v-model="form.postalCode"
                    v-on:keydown="$v.form.postalCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- città -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.city')"
                  :v="$v.form.city"
                >
                  <input
                    class="form-control"
                    maxlength="30"
                    type="text"
                    v-model="form.city"
                    v-on:blur="$v.form.city.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <!-- provincia -->
            <b-row v-if="isIta">
              <b-col sm="12">
                <validation-field
                  :label="$t('message.county')"
                  :v="$v.form.province"
                  v-if="showProvince"
                >
                  <select
                    class="form-control"
                    v-model="form.province"
                    v-on:blur="$v.form.province.$touch()"
                  >
                    <option
                      v-bind:value="county.Code"
                      v-for="county in counties"
                    >
                      {{ county.Description}}
                    </option>
                  </select>
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- telefono -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.telephone')"
                  :v="$v.form.phoneNumber"
                >
                  <input
                    :placeholder="$t('message.placeholder_telephone')"
                    class="form-control"
                    type="text"
                    maxlength="20"
                    v-model="form.phoneNumber"
                    v-on:keydown="$v.form.phoneNumber.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- mobile -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.mobile')"
                  :v="$v.form.mobileNumber"
                >
                  <input
                    :placeholder="$t('message.placeholder_mobile')"
                    class="form-control"
                    type="text"
                    maxlength="20"
                    v-model="form.mobileNumber"
                    v-on:keydown="$v.form.mobileNumber.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row  v-if="showBusinessCode">
              <!-- partita iva -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.vat_number')"
                  :v="$v.form.businessCode"
                >
                  <input
                    class="form-control" type="text"
                    v-model="form.businessCode"
                    maxlength="20"
                    v-on:keydown="$v.form.businessCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="showFiscalCode">
              <!-- codice fiscale -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.fiscal_code')"
                  :v="$v.form.fiscalCode"
                >
                  <input
                    class="form-control"
                    maxlength="20"
                    type="text"
                    v-model="form.fiscalCode"
                    v-on:keydown="$v.form.fiscalCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="showSdiCode">
              <!-- codice SDI -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.sdi_code')"
                  :v="$v.form.sdiCode"
                >
                  <input
                    class="form-control"
                    type="text"
                    maxlength="7"
                    v-model="form.sdiCode"
                    v-on:blur="$v.form.sdiCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="showPec">
              <!-- PEC -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.pec')"
                  :v="$v.form.pec"
                >
                  <input
                    :placeholder="$t('message.placeholder_pec')"
                    class="form-control" type="text"
                    maxlength="250"
                    v-model="form.pec"
                    v-on:blur="$v.form.pec.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <hr class="mb-4">
            <b-row>
              <b-col sm="12" md="4" xl="4">
                <b-button
                  :class="{invalid:$v.form.$invalid}"
                  block
                  class="bg-theme-secondary mb-2"
                  type="submit"

                >
                  {{$t('message.button_save_account_text')}}
                </b-button>
              </b-col>
              <b-col sm="12" md="4" xl="4">
                <b-button
                  block type="button"
                  v-on:click="reset()"
                  variant="warning">
                  {{$t('message.button_reset_text')}}
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>
    </div>
    </div>
  </div>
</template>

<script>

  import ApiResolver from '../../components/common/ApiResolver';
  import Api from '../../components/common/Api';
  import {helpers, minLength,maxLength, required,email} from 'vuelidate/lib/validators'
  import ValidationField from "../../components/ui/ValidationField";

  import store from '@/store';

  const isVatValid = helpers.regex('alpha', /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/);
  const isFiscalCodeValid = helpers.regex('alpha', /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/);
  const isValidPhone = helpers.regex('alpha',/^([+ .\-()0-9]*)$/);



  export default {
    name: 'register',
    components: {
      ValidationField,
    },
    data() {
      return {
        exeVat: null,
        countries: [],
        counties: {},
        form: {
          country: null,
          type: null,
          businessName: null,
          businessName2: null,
          name: null,
          surname: null,
          address: null,
          address2: null,
          city: null,
          province: null,
          phoneNumber: null,
          mobileNumber: null,
          salespersonCode: 'LL',
          businessCode: null,
          fiscalCode: null,
          sdiCode: null,
          pec: null,
          postalCode: null,
        }
      };
    },
    computed: {
      showFiscalCode: function () {
        if (this.isIta ) {
          return true;
        }
        return false;
      },
      showProvince: function () {
        if (this.isIta) {
          return true;
        }
        return false;
      },
      showFirstName: function () {
        if (this.isPrivate) {
          return true;
        }
        return false;
      },
      showLastName: function () {
        if (this.isPrivate) {
          return true;
        }
        return false;
      },
      showPostalCode: function () {
        if (this.isIta) {
          return true;
        }
        return false;
      },
      showBusinessCode: function () {
        if (this.isBusiness || this.isPublic) {

          if (this.isIta) {
            return true;
          }
        }
        return false;
      },
      showBusinessName: function () {
        if (this.isBusiness || this.isPublic || this.isNoProfit ) {

          if (this.isIta) {
            return true;
          }
        }
        return false;
      },
      showSdiCode: function () {
        if (this.isBusiness) {

          if (this.isIta) {
            return true;
          }
        }
        return false;
      },
      showPec: function () {
        if (this.isBusiness) {

          if (this.isIta) {
            return true;
          }
        }
        return false;
      },
      isIta: function () {
        return this.exeVat === 'ITA';
      },
      isUe: function () {
        return this.exeVat === 'UE';
      },
      isXue: function () {
        return this.exeVat === 'XUE';
      },
      isRsm: function () {
        return this.exeVat === 'RSM';
      },
      isVat: function () {
        return this.exeVat === 'VAT';
      },
      isBre: function () {
        return this.exeVat === 'BRE';
      },
      isPrivate: function () {
        return this.form.type === '1';
      },
      isVatValidation: function() {
       return isVatValid;
      },
      isBusiness: function () {
        //console.log(parseInt(this.form.type) === 0 ? 'isBusiness' : 'notIsBusiness');
        //console.log(this.form.type);
        return this.form.type === '0';
      },
      isNoProfit: function () {
        return this.form.type === '2';
      },
      isPublic: function () {
        return this.form.type === '3';
      }
    },
    validations: {
      form: {
        country: {
          required
        },
        type: {
          required
        },
        businessName: {
          isFullNameRequired(value) {
            if (!value && ( this.isBusiness || this.isPublic || this.isNoProfit )) {
              return false;
            }
            return true;
          }
        },
        businessName2: {},
        name: {
          isNameRequired(value) {
            if (!value && this.isPrivate) {
              return false;
            }
            return true;
          },
          min: minLength(2)
        },
        surname: {
          isSurnameRequired(value) {
            if (!value && this.isPrivate) {
              return false;
            }
            return true;
          },
          min: minLength(2)
        },
        city: {
          required,
        },
        address: {
          required
        },
        address2: {},
        postalCode: {
          isPostalCodeRequired(value) {
            if (!value && this.isIta) {
              return false;
            }
            return true;
          },
          isPostCodeToBeValidated(value) {
            if(value && this.isIta  && !value.match(/\b\d{5}\b/)) {
              return false;
            }
            return true;
          }
        },
        province: {
          isProvinceRequired(value) {
            if (!value && this.isIta) {
              return false;
            }
            return true;
          }
        },
        pec: {
          isPecRequired(value, model) {
            if(model['sdiCode'] && model['sdiCode'].length){
              return true;
            }
            if (!value && this.isIta && this.isBusiness) {
              return false;
            }
            return true;
          },
          email: email
        },
        phoneNumber: {
          isValidPhone
        },
        mobileNumber: {
          isValidPhone
        },
        sdiCode: {
          isSdiCodeRequired(value, model) {
            if(model['pec'] && model['pec'].length){
              return true;
            }
            if (!value && this.isIta && this.isBusiness) {
              return false;
            } else if( value && value.length !== 7 && this.isIta && this.isBusiness) {
              return false;
            }
            return true;
          }
        },
        businessCode: {
          isBusinessCodeRequired(value) {
            if (!value && ( this.isBusiness || this.isPublic ) && (this.isIta || this.isUe || this.isBre || this.isRsm)) {
              return false;
            }
            return true;
          },
          isBusinessCodetoBeValidated(value) {
            if(value && this.isIta && !value.match(/\b\d{11}\b/)) {
              return false;
            }
            return true;
          },
        },
        fiscalCode: {
          isFiscalCodeRequired(value) {
            if (!value && ( this.isPrivate|| this.isNoProfit ) && this.isIta) {
              return false;
            }
            return true;
          },
          isFiscalCodeCompanyToBeValidated(value) {
            if(value && this.isIta && this.isBusiness && !value.match(/^([0-9]{11})|([a-zA-Z0-9]{16})$/)) {
              return false;
            }
            return true;
          },
          isFiscalCodePrivateToBeValidated(value) {
            if(value && this.isIta && this.isPrivate && !value.match(/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/)) {
              return false;
            }
            return true;
          }

        }
      }
    },
    methods: {
      async submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        // to form submit after this
        if (this.form.country !== 'IT') {
          this.form.province = 'ZB12';
        }
        let cart = await this.$store.dispatch('cart/get');
        let cartNumber = this.$store.getters['cart/getCartNumber'];

        Api
          .post(ApiResolver.get('REGISTER'), JSON.stringify(this.form))
          .then(response => {

            window.scrollTo(0,0);

            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.save_successfull')
            });

            store.dispatch('user/logout');
            this.$router.push({name: 'login'});

          })
          .catch(reason => {
            this.$store.dispatch('alerts/setAlertValidation', reason);
          });
      },
      reset() {
        this.form = {
          country: null,
          type: null,
          businessName: null,
          businessName2: null,
          name: null,
          surname: null,
          address: null,
          address2: null,
          city: null,
          province: null,
          phoneNumber: null,
          mobileNumber: null,
          salespersonCode: 'LL',
          businessCode: null,
          fiscalCode: null,
          sdiCode: null,
          pec: null,
          postalCode: null
        };
        this.$v.form.reset();


      },
      changeVatScheme: function () {
        this.exeVat = null;
        if (!this.form.country) {
          return ''
        }
        let vatScheme = this.countries.filter((item) => (
          item.Code === this.form.country
        ));
        if (vatScheme.length) {
          this.exeVat = vatScheme[0].EXE_VAT_Scheme;
        }

        return this.exeVat;
      },
    },
    mounted() {

      Api
        .get(ApiResolver.get('COUNTIES_LIST'))
        .then(response => {
          this.counties = response.data;
        })
        .catch(reason => {
          this.isLoading = false;
        });

      Api.get(ApiResolver.get('COUNTRIES_LIST', {}))
        .then((response) => {

          this.countries = response.data;

        })
        .catch((error) => {
          // handle error
          //console.log(error);

        });

    },
  };
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>



